<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default {
  data() {
    return {

    }
  },
  mounted() {

  }
}
</script>
<style lang="stylus">

</style>
