<template>
  <div class="plane">
    <div class="plane-header">
      <div class="huiyuan-body">
        <p>VIP年会员</p>
        <p v-if="!play">仅需66元即可开通</p>
        <p style="border-radius: 14px;background: #265AC6;color: #fff;
          height: 20px;width: 60px;line-height: 20px;text-align: center;" v-if="play">生效中</p>
        <p v-if="!play">开通后立享会员专享权益</p>
        <p v-if="play">有效期至 2025-03-20</p>
      </div>
    </div>
    <!-- <div>{{ wxResult }}</div> -->
    <div class="product-body">
      <h2>会员尊享三重权益</h2>
      <div class="items-play" v-if="play">
        <p class="goods">价值268元好礼免费领</p>
        <p class="good-desc" style="margin-top:5px;">
          免费领取价值 268 元的人源化胶原蛋白贴敷料 1盒，肌肤者宠即刻开启。
        </p>
        <div class="content">
          <p>礼品领取码</p>
          <img :src="url" alt="">
          <p>请向工作人员出示二维码<br />领取礼品</p>
        </div>
      </div>
      <div class="items" v-if="!play">
        <p class="goods">价值268元好礼免费领</p>
        <p class="good-desc" style="margin-top:5px;">
          免费领取价值 268 元的人源化胶原蛋白贴敷料 1盒，肌肤者宠即刻开启。
        </p>
      </div>
      <div class="items">
        <p class="goods">胶原中心全系列项目95折</p>
        <p class="good-one">或 购买项目免费获赠1个指定项目</p>
        <p class="good-desc">享1年，解锁全新美丽可能。</p>
      </div>
      <div class="items">
        <p class="goods">92折优惠，囤货无忧</p>
        <p class="good-desc">享1年期商城内胶原蛋白贴敷料或双胶原抗皱紧素次抛精华 92折优惠，囤货无忧，让美丽更具性价比。</p>
      </div>
    </div>
    <div class="plane-footer" v-if="isShow">
      <div class="plane-desc">
        <span class="tequan">仅<em>66</em>元/年</span>
        <span class="tequan" style="margin-left:10px;">享一年会员特权</span>
      </div>
      <div class="footer-field">
        <input placeholder="请输入手机号" v-model="tell" class="input" />
        <div @click="handelClicktell()" class="btn-img">
          <span>立即开通</span>
        </div>
      </div>
    </div>
    <!-- 模态框 -->
    <div>{{ demo1 }}</div>
    <div>{{ demo2 }}</div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Field, MessageBox, Header, Toast } from 'mint-ui';
import axios from 'axios';
import wx from 'weixin-js-sdk';

export default {
  name: "",
  components: {
    Field: Field.name,
    MessageBox: MessageBox.name,
    Header: Header.name,
    Toast: Toast.name
  },
  data() {
    return {
      tell: '',
      play: false,
      wxResult: {}, // 测试用对象
      openid: '',
      show: false,
      demo1: '',
      demo2: '',
      isShow: true, //
      // openid: 'oz47z7Lehp-kKbw7qO9TabGpGnrM'  // 本地调试用
    }
  },
  created() {
    if (this.getToken()) {
      this.handelInitdataStatus()
    } else {
      if (process.env.NODE_ENV === 'production') {
        this.handelgetweixinCode() 
      }
    } 
    // setTimeout(() => { // 预防无法获取openid
    //   this.handelgetUersInfo()
    // }, 2000)
  },
  methods: {
    getToken() {
      return window.localStorage.getItem('token');
    },
    getOpenid() {
      if (!this.openid){
        this.openid = window.localStorage.getItem('openid');
      }
      if (!this.openid) {
        this.handelgetweixinCode();
      }
      return this.openid;
    },
    handelInitdataStatus() { // 获取会员状态
      const _url = '/api/client/giftRecord/vipGiftRecord'
      const token = this.getToken()
      axios.get(_url, {
        headers: {
          'Authorization': token
        }
      }).then(response => {
        const d = response.data;
        this.demo = response.data
        if(d.code == 0) {
          if (d.data == null) {
            // 不是会员
            this.isShow = true
            this._handelgetUserInfo()
          } else {
            // 是会员
            if (d.data.status == 0) {
              // 会员未领取
              this.$router.push('/about')
            } else if (d.data.status == 1){
              // 会员已领取
              this.$router.push('/succeed')
            }
          }
        } else if (d.code == 401) {
          this.handelgetweixinCode()
        } else {
          // 其他错误信息
        }
      }).catch(error => {
        window.localStorage.removeItem('token')
      })
    },
    _handelgetUserInfo() { // 获取用户信息事件处理函数
      const token = this.getToken()
      axios('/api/client/user/info', {
        headers: {
          'Authorization': token
        }
      }).then(res => {
        if(res.data.code == 0) {
          this.tell = res.data.data.userPhone
        }
     }).catch(error => {
        window.localStorage.removeItem('token')
        Toast({
          message: error.response.data.msg,
          duration: 3000
        });
     })
    },
    handelgetWeixinOpenid(code) {  // 获取openid
      axios({
        method: 'post',
        url: '/api/client/wx/getOpenid',
        data: { code },
        header: { 'Content-Type': 'application/json' }
      }).then(response => {
        if(response.data.code == 0) {
          this.openid = response.data.data.openid
          if(this.openid) {
            localStorage.setItem('openid', this.openid)
          } else {
            localStorage.removeItem('openid')
          }
          this.handelgetUersInfo()
        }
      }).catch(error => {
        // Toast({
        //   message: error.response.data.msg,
        //   duration: 3000
        // })
        window.localStorage.removeItem('token')
      })
    },
    handelgetweixinCode() { // 获取微信code
      let appid = "wx45d807625cb24ac5"; //公众号appid
      let redirect = encodeURIComponent(window.location.href); //重定向回来的地址
      let wx_code = this._getUrlParam("code");
      //获取code的地址。获取成功重定向后地址栏中将会带有code，判断没有code的话，就跳转到微信官方链接上获取，获取成功后会再重定向回来，注意url是需要使用encodeURIComponent处理一下编码的
      if (!wx_code) {
        // scope: 必传；应用授权作用域，snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect";
      } else {
        this.handelgetWeixinOpenid(wx_code); //把code传给后台获取用户信息
      }
    },
    _getUrlParam(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null)
        return unescape(r[2]);
      return null;
    },
    handelgetUersInfo() { // 通过openid获取token事件处理函数
      axios({
        url: '/api/client/user/login/wechat',
        data: { openId: this.getOpenid()},
        header: { 'Content-Type': 'application/json' },
        method: 'post',
      }).then(res => {
          if (res.data.code == 0) {
          window.localStorage.setItem('token', res.data.data.token)
          window.localStorage.setItem('userId', res.data.data.userId)
          this.handelInitdataStatus()
          } else {

          }
      }).catch(error => {
        window.localStorage.removeItem('token')
        this.demo1 = error.response.data.msg
      })
    },
    async handelAsyncgetUersInfo() { // 通过openid获取token事件处理函数
      const res = await axios({
        url: '/api/client/user/login/wechat',
        data: { openId: this.getOpenid(), phone: this.tell },
        headers: { 'Content-Type': 'application/json',  'Authorization': this.getToken()},
        method: 'post',
      })
      if (res.data.code == 0) {
        window.localStorage.setItem('token', res.data.data.token)
        window.localStorage.setItem('userId', res.data.data.userId)
        // this.handelInitdataStatus()
        return 0
      } else {
        Toast({
          message: res.data.msg,
          duration: 2000
        })
        this.demo2 = res.data.msg
        window.localStorage.removeItem('token')
        return 1
      }
    },
    handelClicktell() { // 提交用户绑定信息事件函数
      if (this.tell == '') {
        return false
      }
      this.handelClickShowplay()
    },
    handelClickShowplay() { // 现实微信支付弹窗
      const token = this.getToken()
      MessageBox({
        title: this.tell,
        message: '请确认手机号是否正确',
        showCancelButton: true,
        confirmButtonText: "立即支付",
        cancelButtonText: '继续修改'
      }).then(async res => {
        if(!token) {
          this.getOpenid()
          let res = await this.handelAsyncgetUersInfo()
          if(res == 1) {
            return
          }
        }
        this.handelPalyMonment()
      })
    },
    handelPalyMonment() { // 微信内置浏览器支付事件函数
      const token = this.getToken()
      const openid = this.getOpenid()
      axios({
        url: '/api/client/payOrder/wechat',
        headers: { 'Content-Type': 'application/json', 'Authorization': token },
        method: 'post',
        data: { openId: openid, goodsId: 1 }
      }).then(res => {
        const d = res.data
        if (d.code !== 0) {
          // alert('pay.res:' + JSON.stringify(res.data))
          Toast({
            message: d.message,
            duration: 3500
          })
          return
        }
        const { appId, timeStamp, nonceStr, signature, paySign, signType } = res.data.data
        wx.config({
          debug: false, // 是否开启调试模式
          appId: appId, // 必填，公众号的唯一标识
          timestamp: timeStamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ['chooseWXPay']
        })
        wx.ready(() => {
          wx.chooseWXPay({
            appId: appId, // 必填，公众号的唯一标识
            timestamp: timeStamp, // 必填，生成签名的时间戳
            nonceStr: nonceStr, // 必填，生成签名的随机串
            paySign: paySign,
            signature: signature, // 必填，签名
            package: res.data.data.package, // 统一支付接口返回的prepay_id参数值
            signType: signType,
            success: (r) => {
              if (r.errMsg == "chooseWXPay:ok") {
                setTimeout(() => {
                  this.$router.push('/about')
                }, 500)
              }
            }
          })
        })
        wx.error(res => {
          Toast({
            message: '支付失败',
            duration: 3500
          })
        })
      }).catch(error => {
        window.localStorage.removeItem('token')
        Toast({
            message: error.response.data.msg,
            duration: 3500
        })
      })
    }
  }
};
</script>

<style scoped lang="stylus">
.plane
  min-height 100vh
  padding-bottom: 170px
  .plane-header
    height: 36rem
    padding-top: 2rem
    background: url('../assets/images/header.png') no-repeat center center
    background-size: cover
    overflow hidden
    .huiyuan-body
      height: 36rem;
      background: url('../assets/images/huiyuan.png') no-repeat center center
      background-size: contain
      overflow hidden
      width: 67rem
      margin 0 auto
      p
        margin-left 5.4rem
        margin-bottom 1rem
        &:nth-of-type(1)
          margin-top 8.5rem
          font-family: PingFang SC;
          font-size: 4rem;
          font-weight: bold;
          line-height: normal;
          letter-spacing: 0em;
          font-variation-settings: "opsz" auto;
          color: #265AC6;
        &:nth-of-type(2)
          border-radius: 12px
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: normal;
          width: 22rem;
          height: 3.4rem;
          line-height: 3.4rem
          padding-left 5px
          color: #13141A;
          background: linear-gradient(270deg, #FFF9ED 1%, #FDAC7A 100%);
        &:nth-of-type(3)
          font-family: PingFang SC;
          font-size: 2.4rem;
          font-weight: 600;
          font-variation-settings: "opsz" auto;
          color: #265AC6;
          margin-top 7rem;
  .plane-footer
    position: fixed
    left 0
    right 0
    height: 35rem
    background: url('../assets/images/footer.png') no-repeat center center
    background-size: 100% 100%
    bottom: -25px
    padding: 20px 20px 40px
    z-index 5
    .plane-desc
      text-align: center
      margin-bottom 28px
      .tequan
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0em;
        font-variation-settings: "opsz" auto;
        background: linear-gradient(103deg, #FFFAEF 5%, #FAD6C4 90%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        em
          display: inline-block
          font-family: PingFang SC;
          font-size: 36px;
          font-weight: 600;
          letter-spacing: 0em;
          font-variation-settings: "opsz" auto;
          background: linear-gradient(124deg, #FFFAEF 3%, #FAD6C4 84%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
  .footer-field
    .btn-img
      height: 10.8rem;
      background: url('../assets/images/btn.png') no-repeat center center
      background-size: cover
      text-align: center;
      line-height: 54px
      span
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;
        font-variation-settings: "opsz" auto;
        background: linear-gradient(180deg, #265AC6 0%, #13141A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    .input
      border-radius: 24px;
      background: #FFFFFF;
      height: 8.8rem;
      display: block
      width: 93%
      outline: none
      border none
      text-indent: 16px
      font-size: 15px
      margin: 0 auto 20px
  .product-body
    background: url('../assets/images/背景切图@1x.png') no-repeat center center
    background-size: 100% 100%;
    min-height: 115.6rem
    // height: 500px
    margin-top -16px
    overflow hidden
    h2
      text-align: center
      font-size: 4rem;
      font-weight: 900;
      line-height: normal;
      text-align: center;
      letter-spacing: 0em;
      background: linear-gradient(101deg, #FFFAEF 5%, #FAD6C4 91%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      margin-bottom 5rem
      position relative
      top 7rem
    .items
      height: 30rem;
      background: #ccc;
      margin: 0 4rem;
      border-radius: 12px
      overflow hidden
      &:nth-of-type(1)
        background: url('../assets/images/权益一切图.png') no-repeat center center
        background-size: contain
        margin-top 11rem
      &:nth-of-type(2)
        background: url('../assets/images/权益二切图.png') no-repeat center center
        background-size: contain
      &:nth-of-type(3)
        background: url('../assets/images/权益三切图.png') no-repeat center center
        background-size: contain
        .goods
          margin-top: 10rem
      .good-one
        font-size: 3rem;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;
        background: linear-gradient(180deg, #265AC6 0%, #13141A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-left: 22px;
      .goods
        font-size: 3.6rem;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;
        margin-top 11rem;
        font-variation-settings: "opsz" auto;
        background: linear-gradient(180deg, #265AC6 0%, #13141A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-left 4rem
      .good-desc
        margin-left 4rem
        font-size: 2.4rem;
        font-weight: 350;
        line-height: 17px;
        letter-spacing: 0em;
        width: 36rem;
        color: #9EA0A7;
    .items-play
      height: 94rem;
      background: url('../assets/images/copy.png') no-repeat center center
      background-size: cover
      margin-left 4rem
      margin-right 4rem
      position relative
      margin-bottom 12px
      .content
        height: 40rem
        width: 40rem
        position: absolute
        left: 50%
        top 50%
        margin-left -20rem
        margin-top -8rem
        p
          &:nth-of-type(1)
            font-size: 3.6rem;
            margin-bottom 2rem
            text-align: center
          &:nth-of-type(2)
            text-align: center
            margin-top 10px
            font-size: 14px;
            line-height: 20px;
        img
          display: block;
          height: 33rem
          width: 33rem
          margin: 0 auto
      .goods
        font-size: 3.6rem;
        position relative;
        top 45px
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0em;
        margin-top: 11rem;
        background: linear-gradient(180deg, #265ac6 0%, #13141a 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-left: 4rem;
      .good-desc
        width: 36rem;
        position: relative;
        top: 50px;
        left: 21px;
        line-height: 17px;
        color: #9EA0A7
@media screen and (min-width: 411px)
  .plane .product-body
    margin-top: -18px;
</style>

<style>

.mint-header {
  background-color: transparent !important;
}

.mint-header.is-fixed {
  top: 8px !important
}

.mint-msgbox-title {
  font-size: 18px !important;
}

.mint-msgbox-btn {
  font-size: 16px;
}
</style>
