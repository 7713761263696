import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import '@/assets/styles/normalize.css'
import '@/assets/js/rest'
import 'mint-ui/lib/style.css';

import Mint from 'mint-ui';
Vue.use(Mint);

document.title = '入会有礼'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
